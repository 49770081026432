<template>
<div>
    <!--CRow id="top_addscreen">
       
        
    </CRow-->
    <CRow v-if="this.$store.state.user.abos[0].pivot.moyen_paiement_a_jour == 1">    
        <ScreenCard v-for="s in screens" :screen="s" :key="s.id"></ScreenCard> 
        <CCol sm="6" md="4" xl="2" class="mb-3">
            <CCard>
                
                <CCardBody class="body-block">
                    
                    <div class="text-center">
                    <div><i class=" material-icons" style="font-size: 70px">add_circle</i></div>
                     <button class="btn btn-primary" @click="add">{{ $t("add_screen") }}</button>
                     <div v-if="error" style="margin-top:10px;"><CAlert color="warning">{{errorMsg}}</CAlert></div>
                    </div>
                    
                </CCardBody>
                
            </CCard>
        </CCol>
    </CRow>
    <Crow v-else>
        <CAlert color="danger"><router-link to="/dashboard/account">{{ $t("merci_mettre_a_jour") }}</router-link></CAlert>
    </Crow>
</div>
</template>

<script>
import ScreenCard from '@/components/ScreenCard.vue'

export default {
  name: "Dashboard",
  components:{
      ScreenCard
  },
  data(){
      return{
          screens: null,
          error: 0,
          errorMsg: ""
      }
  },
  created(){
      let self = this;
      window.axios.get('/api/screens').then((rep) => {
          self.screens = rep.data.screens
          console.log(self.screens)
      });
  },
  methods: {
      async add(){
          /*this.screens.push({id: 333, name: "test", bandeau: "dddddd"})
          console.log('ADD')
          console.log(this.screens)*/
          try {
                let rep = await window.axios.post("/api/screen");
                this.$router.push({ path: '/screen/'+rep.data })
            } catch (err) {
                console.log(err.response.data);
                this.error = 1
                if(err.response.data.message == "not_allowed")
                    this.errorMsg = this.$t("max_screen")
                else
                    this.errorMsg = "network_error"

            }

      }
  }
};
</script>

<style scoped>
#top_addscreen{
   
    padding-left: 16px;
    margin-bottom: 20px;
    align-items: center;
}
.warning_msg{
    padding-left: 15px;
    color:red;
    margin-bottom: 0;
}

:focus {
  outline: none !important;
  box-shadow: none !important;
}

.body-block{
      display: flex;
    flex-direction: column;
    justify-content: center;
}

.card{
   height: 100%;
}

.alert a{
    color: #772b35;
    text-decoration: underline;
}
</style>
